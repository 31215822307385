import LOGO from 'assets/img/brand/BizHeadLogoCut.png'


let keys = {
    TIMEZONE: 'America/Los_Angeles',
    COMPANY: 'Biz Head Law',
    LOGO
};

const HOSTNAME = window.location.hostname

//using production keys
if(HOSTNAME === 'auth.mybizheadlaw.com') {

    keys = {
        ...keys,
        API_URL               : 'https://api.mybizheadlaw.com',
        APP_URL               : 'https://mybizheadlaw.com',
        ADMIN_URL             : 'https://admin.mybizheadlaw.com',
        CLIENT_URL            : 'https://clients.mybizheadlaw.com',
        LIVE_URL            : 'https://live.mybizheadlaw.com',

        SYSTEM_API_KEY        : 'architeck-3876143876',
        ZEAMSTER_ORIGIN       : 'https://api.fortispay.com',
        env                   : 'prod',

    }

//using staging keys
} else if(HOSTNAME === 'staging.auth.mybizheadlaw.com') {

    console.log('s')

    keys = {
        ...keys,
        API_URL               : 'https://staging.api.mybizheadlaw.com',
        APP_URL               : 'https://staging.mybizheadlaw.com',
        ADMIN_URL             : 'https://staging.admin.mybizheadlaw.com',
        CLIENT_URL            : 'https://staging.clients.mybizheadlaw.com',
        LIVE_URL            : 'https://staging.live.mybizheadlaw.com',

        SYSTEM_API_KEY        : 'architeck-4874517265',
        ZEAMSTER_ORIGIN       : 'https://api.sandbox.zeamster.com',
        env                   : 'staging',

    }

//using development keys
} else {

    console.log('d')

    // wrapped in try catch as this will fail in staging and production
    // since this file does not exist in version control. Only used for dev purposes
    try {
       
        keys = {
            ...keys,
            API_URL               : 'http://localhost:5010',
            APP_URL               : 'http://localhost:3011',
            ADMIN_URL             : 'http://localhost:3012',
            CLIENT_URL            : 'http://localhost:3013',
            LIVE_URL              : 'http://localhost:3016',
    
            SYSTEM_API_KEY        : 'architeck-4874517265',
            env                   : 'dev',
    

        }

   } catch(e) {}

}

export default keys
