/*
Documentation

this page handles log in for a user
on successful login we set a cookie architeck_uid
when we send off to see if the user is logged in this is
passed along as well to prevent cors cookie issues

*/

import classnames from "classnames";
import keys from 'keys';
import React from "react";
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Row, CardHeader, CardTitle, CardFooter } from "reactstrap";
import _auth from '_functions/auth';
import { getUrlParameter } from 'utils/urls';
import { connect } from 'react-redux';

class Login extends React.Component {

	state = {
        email: window.location.hostname === 'localhost' ? 'systems@bizheadlaw.com' : '',
        password: window.location.hostname === 'localhost' ? 'Aaaa123*' : ''
    };

	//on keypress of enter simulate the form being submitted for better UI
	_handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			document.getElementById("loginButton").click();
		}
	}

	onLogin = async () => {

        this.setState({error: null})

		const email = this.state.email;
		const password = this.state.password;

		if(!this.state.email) {
			this.setState({error: 'Invalid Email'});
			return;
		}

		if(!this.state.password) {
			this.setState({error: 'Invalid Password'});
			return;
		}

        //validate the users password
		const user = await _auth.password.validate({email, password})

        if(user.success) {

			// if user has been deleted kick the out
			if(user.user.deleted) {
				alert(`Unauthorized! Your account has been deactivated.`)
				window.location.href = '/auth/logout'
			}

            const redirect = getUrlParameter('redirect');

			if(redirect && (redirect.includes(keys.APP_URL) || redirect.includes(keys.ADMIN_URL) || redirect.includes(keys.LIVE_URL))) {
				window.location = redirect;
			} else {
				window.location = keys.APP_URL + '/dashboard/cases/all';
			}

        } else {

			if(user.message === 'architeckCall failed at url: /api/v1/auth/password_validate. Error: Error: Network Error') {
				this.setState({error: `Login failed, please try again in 30 seconds.`});
			} else {
				this.setState({error: user.message});
			}

        }

	}

	componentDidMount = () => {
		const viewing_user = this.props.viewing_user;

		if(viewing_user && viewing_user.logged_in) {

			const redirect = getUrlParameter('redirect');

			if(redirect && (redirect.includes(keys.APP_URL) || redirect.includes(keys.ADMIN_URL))) {
				window.location = redirect;
			} else {
				window.location = keys.APP_URL;
			}

		} else {
			this.setState({loaded: true})
		}
	}

	render() {

		if(!this.state.loaded) return <div />

		return (

		<>

			<Helmet>
				<title>Login</title>
				<meta name="description" content="Login" />
			</Helmet>

            <div className="header  py-7 py-lg-5 mb-lg-5 pt-lg-3">
                <Container>
                    <div className="header-body text-center mb-7">
                        <Row className="justify-content-center">
                            <Col className="px-5" lg="6" md="8" xl="5">

                                <img style={{width: 250}} alt="..."  src={keys.LOGO} />
                                <p className="text-white mt-3">Log In Here</p>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

			<Container className="mt--9 pb-5">
				<Row className="justify-content-center">
					<Col lg="5" md="7">

						<Form role="form">
						
							<Card className="bg-white border-0 mb-0" style={{boxShadow: 'rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px'}}>

								<CardHeader>
									<CardTitle className="mb-0 text-center">Sign in To Your Account</CardTitle>
								</CardHeader>

								<CardBody className="">

										<FormGroup className={classnames("mb-3", { focused: this.state.focusedEmail })} >
											<label className="form-control-label">Email</label>
											<Input
												name="email"
												onKeyDown={this._handleKeyDown}
												placeholder="Email"
												type="email"
												autoComplete="email"
												value={this.state.email	 || ''}
												onChange={(e) => this.setState({email: e.target.value})}
												onFocus={() => this.setState({ focusedEmail: true })}
												onBlur={() => this.setState({ focusedEmail: false })}
											/>
										</FormGroup>

										<FormGroup className="mt-4">
											<label className="form-control-label">Password</label>
											<Input
												name="password"
												onKeyDown={this._handleKeyDown}
												placeholder="Password"
												type="password"
												autoComplete="current-password"
												value={this.state.password || ''}
												onChange={(e) => this.setState({password: e.target.value})}
												onFocus={() => this.setState({ focusedPassword: true }) }
												onBlur={() => this.setState({ focusedPassword: false })}
											/>
										</FormGroup>

										
								</CardBody>

								<CardFooter className="text-center">
									{this.state.error && <span className="text-danger small font-weight-bold pb-3 d-block">{this.state.error}<br /></span>}
									<Button id="loginButton" onClick={this.onLogin} className="btn-block" color="success" type="button">
										Sign in
									</Button>
								</CardFooter>

							</Card>

						</Form>


						<Row className="mt-3">

							<Col xs="12" className="text-center">
								<Link className="text-light" to="/forgot" >
									<small>Forgot Password?</small>
								</Link>
							</Col>

						</Row>

					</Col>
				</Row>
			</Container>
		</>
		);
	}
}


const mapStateToProps = state => {
    return {
      viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(Login);

