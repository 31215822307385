/*
Documentation

load all styles, assets, here
App is wrapped in a store provider

*/


import React from "react";
import ReactDOM from "react-dom";

import "assets/scss/index.scss";

import App from './App'

import { Provider } from 'react-redux';
import store from 'store';

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("root")
);

